import React from 'react';

import Container from '../Container/Container';
import LoadingSpinner, { OwnProps as LoadingProps } from './LoadingSpinner';

interface OwnProps extends LoadingProps {}

type Props = Readonly<React.PropsWithChildren<OwnProps>>;

const Loading: React.FC<Props> = ({ children, ...loadingProps }) => {
  return (
    <ErrorBoundary>
      <React.Suspense fallback={<LoadingSpinner {...loadingProps} />}>{children}</React.Suspense>
    </ErrorBoundary>
  );
};

interface ErrorBoundaryState {
  hasError: boolean;
  info: string;
}

class ErrorBoundary extends React.PureComponent<unknown, ErrorBoundaryState> {
  state: ErrorBoundaryState = {
    hasError: false,
    info: '',
  };

  static getDerivedStateFromError(error: any) {
    console.error(error);
    return {
      hasError: Boolean(error),
      info: String(error),
    };
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <article className="my-5">
          <Container className="text-center">
            {/* eslint-disable-next-line react/jsx-no-literals, jsx-a11y/accessible-emoji */}
            <h1>🚑 Something went wrong 🚒</h1>
            {/* eslint-disable-next-line react/jsx-no-literals */}
            <p className="h3">please contact us</p>
            <code>
              <pre>{this.state.info}</pre>
            </code>
          </Container>
        </article>
      );
    }

    return this.props.children;
  }
}

export default React.memo(Loading);
