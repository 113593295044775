import classNames from 'classnames';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Button from 'reactstrap/lib/Button';
import ButtonGroup from 'reactstrap/lib/ButtonGroup';
import Media from 'reactstrap/lib/Media';
import CustomInput from 'reactstrap/lib/CustomInput';

import { modifyQuantityInCart, modifyVariantInCart, removeFromCart, removeSeatFromCart } from '@ttstr/actions';
import { CartItem } from '@ttstr/api';
import { useActions } from '@ttstr/utils';

import Currency from '../Intl/Currency';
import DateComponent from '../Intl/DateComponent';
import NumberComponent from '../Intl/NumberComponent';
import { useProductDetailLink } from '../ProductDetail/ProductDetailLinkContext';

interface OwnProps {
  item: CartItem;
  readOnly?: boolean;
}

type Props = Readonly<OwnProps>;

const CartListingRow: React.FC<Props> = ({ item, readOnly }) => {
  const { t } = useTranslation();
  const productDetailLink = useProductDetailLink();
  const [busy, setBusy] = React.useState(false);
  const abortController = React.useRef(new AbortController());
  const { modifyQuantityInCart, modifyVariantInCart, removeFromCart, removeSeatFromCart } = useActions(
    mapDispatchToProps
  );

  React.useEffect(() => {
    return () => abortController.current.abort();
  }, []);

  const handleModifyQuantity = React.useCallback(
    (cartItemId: number, newQuantity: number) => async () => {
      try {
        setBusy(true);
        await modifyQuantityInCart(cartItemId, newQuantity);
      } finally {
        if (!abortController.current.signal.aborted) setBusy(false);
      }
    },
    [setBusy, modifyQuantityInCart]
  );

  const handleModifyVariant = async (seatId: number, variantId: number) => {
    const seats = item.seats.map((seat) => {
      return {
        seatId: seat.id,
        variantId: seat.variant.id,
      };
    });

    const newSeats = [...seats.filter((s) => s.seatId !== seatId), { seatId, variantId }];

    try {
      setBusy(true);
      await modifyVariantInCart(item.id, newSeats);
    } finally {
      if (!abortController.current.signal.aborted) setBusy(false);
    }
  };

  const handleRemoveItem = React.useCallback(
    (cartItemId: number) => async (e: React.MouseEvent) => {
      e.preventDefault();
      if (confirm(t(`CART.CONFIRM_REMOVE_ITEM`))) {
        try {
          setBusy(true);
          await removeFromCart(cartItemId);
        } finally {
          if (!abortController.current.signal.aborted) setBusy(false);
        }
        // Afterwards the item should be removed, no need to reset the busy
      }
    },
    [t, setBusy, removeFromCart]
  );

  const handleRemoveSeat = React.useCallback(
    (seatId: number) => async () => {
      if (confirm(t(`CART.CONFIRM_REMOVE_ITEM`))) {
        try {
          setBusy(true);
          await removeSeatFromCart(seatId);
        } finally {
          if (!abortController.current.signal.aborted) setBusy(false);
        }
      }
    },
    [t, setBusy, removeSeatFromCart]
  );

  const { article } = item.variant;
  const hasSeats = item.seats && Boolean(item.seats.length);
  const isPackage = Boolean(item.cart_items);

  return (
    <tr key={item.id} className={classNames({ loading: busy })}>
      <td colSpan={hasSeats ? 2 : 1}>
        <Media className="align-items-start">
          {!readOnly && article.image && (
            <Media
              object
              src={article.image.thumb.url}
              className="product-thumb mr-3"
              width="50"
              height="50"
              loading="lazy"
            />
          )}
          <Media body>
            <Media heading tag="h6">
              {/* eslint-disable-next-line react/jsx-no-literals */}
              <NumberComponent value={item.quantity} /> &times;{' '}
              {readOnly || item.variant.is_fee || item.variant.is_option ? (
                item.variant.full_title
              ) : (
                <Link to={productDetailLink(article)}>{item.variant.full_title}</Link>
              )}
            </Media>
            {article.valid_start_on && article.location && (
              <p>
                {/* eslint-disable-next-line react/jsx-no-literals */}
                <DateComponent value={article.valid_start_on} /> - {article.location}
              </p>
            )}
            {hasSeats && (
              <ul className="list-unstyled">
                {item.seats.map((seat) => (
                  <li key={seat.position} className="d-flex flex-column justify-content-between align-items-start">
                    <span>
                      {/* eslint-disable-next-line react/jsx-no-literals */}
                      {seat.position}
                      {/*  &ndash; <Currency value={item.variant.price} /> */}
                    </span>
                    {!readOnly && (
                      <span className="cart-listing-variant-option">
                        <CustomInput
                          id={seat.id}
                          type="select"
                          bsSize="sm"
                          value={seat.variant.id}
                          onChange={(event) => handleModifyVariant(seat.id, Number(event.target.value))}
                        >
                          {item.variant.versions.map((version) => (
                            <option key={version.id} value={version.id}>
                              {version.title}
                            </option>
                          ))}
                        </CustomInput>
                        <Button
                          type="button"
                          color="danger"
                          size="sm"
                          onClick={handleRemoveSeat(seat.id)}
                          className="ml-1"
                        >
                          <i className="fal fa-trash" />
                        </Button>
                      </span>
                    )}
                  </li>
                ))}
              </ul>
            )}
            {isPackage && (
              <ul className="m-0">
                {item.cart_items.map((cartItem) => (
                  <li key={cartItem.variant.id}>
                    <small>{cartItem.variant.full_title}</small>
                    {cartItem.variant.valid_start_on && cartItem.variant.location && (
                      <small>
                        {/* eslint-disable-next-line react/jsx-no-literals */}
                        &nbsp;- <DateComponent value={cartItem.variant.valid_start_on} /> - {cartItem.variant.location}
                      </small>
                    )}
                  </li>
                ))}
              </ul>
            )}
            {!readOnly && !item.variant.is_fee && (
              <Button color="link" className="text-muted remove-item p-0" onClick={handleRemoveItem(item.id)}>
                {t(`PRODUCT.REMOVE`)}
              </Button>
            )}
          </Media>
        </Media>
      </td>
      {!item.variant.is_fee && !hasSeats && !readOnly && (
        <td className="text-center a">
          <ButtonGroup size="sm">
            <Button className="decrease" color="secondary" onClick={handleModifyQuantity(item.id, item.quantity - 1)}>
              <i className="fal fa-minus" /> <span className="sr-only">{t(`CART.SUBTRACT_ITEM`)}</span>
            </Button>
            <Button
              className="increase"
              color="secondary"
              disabled={!item.variant.remaining_for_online}
              onClick={handleModifyQuantity(item.id, item.quantity + 1)}
            >
              <i className="fal fa-plus" /> <span className="sr-only">{t(`CART.ADD_ITEM`)}</span>
            </Button>
          </ButtonGroup>
        </td>
      )}
      <td className="text-right d-none-sm">
        <Currency value={item.total_price} />
      </td>
    </tr>
  );
};

const mapDispatchToProps = {
  modifyQuantityInCart,
  modifyVariantInCart,
  removeFromCart,
  removeSeatFromCart,
};

export default React.memo(CartListingRow);
