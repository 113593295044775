import React from 'react';

import { useFormContext } from 'react-hook-form';

import Badge from 'reactstrap/lib/Badge';
import CustomInput from 'reactstrap/lib/CustomInput';
import ListGroupItem from 'reactstrap/lib/ListGroupItem';

import Currency from '../Intl/Currency';

interface OwnProps {
  iconUrl?: string;
  inputId: string;
  name: string;
  value: string;
  title: string;
  description?: string;
  price?: number;
  autoFocus?: boolean;
  disabled?: boolean;
  defaultChecked?: boolean;
  classNames?: string;
}

type Props = Readonly<OwnProps>;

const RadioListItem: React.FC<Props> = ({
  iconUrl,
  inputId,
  title,
  description,
  price,
  name,
  value,
  autoFocus,
  disabled,
  defaultChecked,
  classNames,
}) => {
  const { register, watch } = useFormContext();
  const active = watch(name) === String(value);

  return (
    <ListGroupItem
      color="secondary"
      tag="label"
      htmlFor={inputId}
      active={active}
      disabled={disabled}
      action
      className={classNames}
    >
      <div className="media align-items-center">
        {iconUrl && <img src={iconUrl} alt={title} className="product-thumb mr-2" loading="lazy" />}
        <CustomInput
          id={inputId}
          type="radio"
          name={name}
          value={value}
          defaultChecked={defaultChecked}
          className="mr-1"
          autoFocus={autoFocus}
          disabled={disabled}
          innerRef={register({ required: true }) as any}
        />
        <div className="media-body">
          <h5 className="m-0 d-flex justify-content-between align-items-start">
            <span className="text-break">{title}</span>
            {price != null && (
              <Badge color="secondary" pill className="ml-1">
                {/* eslint-disable-next-line react/jsx-no-literals */}
                {price !== 0 && <>+ </>}
                <Currency value={price} />
              </Badge>
            )}
          </h5>
          {description && <p className="mt-2 mb-0">{description}</p>}
        </div>
      </div>
    </ListGroupItem>
  );
};

export default React.memo(RadioListItem);
